<template>
  <div class="nav">
    <div class="link" :class="{ active: isRoute('/skills')}" id="skill">
      <router-link to='/skills'>
        <h3>Skills</h3>
      </router-link>
    </div>
    <div class="link" :class="{ active: isRoute('/experience')}" id="exp">
      <router-link to='/experience'>
        <h3>Experience</h3>
      </router-link>
    </div>
    <div class="link" :class="{ active: isRoute('/qualifications')}" id="qual" >
      <router-link to='/qualifications'>
        <h3>Qualifications</h3>
      </router-link>
    </div>
    <div class="link" :class="{ active: isRoute('/languages')}" id="pl">
      <router-link to='/languages'>
        <h3>Languages</h3>
      </router-link>
    </div>
    <!--div class="link" id="cont">
      <router-link to='/contact'>
        <h3 :class="{ active: this.$store.getters.iscont }">Contact Me</h3>
      </router-link>
    </div-->
  </div>
</template>

<script>
export default {
  name: 'Nav',
  methods: {
    isRoute(curRoute) {
      return this.$route.path == curRoute
    }
  }
}
</script>

<style scoped>

.nav {
  display: flex;
  /* min-height: 60%; */
  flex-direction: column;
  justify-content: center;
  /* flex: 1; */
  /* margin-top: 20px; */
  color: #222;
}

a {
  color: inherit;
  text-decoration: none;
}
.link {
  margin: 8px 0;
  padding: 4px 12px;
  transition: all 0.1s ease;
}

.link:hover {
  background-color: #222;
  color: #fff;
  /* text-shadow: 1px 1px 4px #ccc; */
}

.active {
  border-radius: 3px;
  background-color: #222;
  color: #fff;
}
</style>
