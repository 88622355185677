<template>
  <main>
    <section id="main-section">
      <Map></Map>
      <div class="data">
        <router-view></router-view>
        <!--router-view v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <div :key="route.name">  
              <component :is="Component"></component>
            </div>  
          </transition>
        </router-view-->
      </div>
    </section>
    <!--div class="circle1"> </div>
    <div class="circle2"> </div-->
  </main>
</template>

<script>
import Map from '@/components/Map.vue'

export default {
  name: 'Kaustubh',
  components: {
    Map
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: linear-gradient(to top right, #a1c4fd, #c2e9fb);
  height: 100vh;

  backdrop-filter: blur(104em);
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to top right, #c79081, #dfa579); */
  height: 100%;
  z-index: 3;
  backdrop-filter: blur(104em);
}

#main-section {
  display: grid;
  grid-template-columns: 300px auto;
}

#main-section * {
}

section {
  display: flex;
  min-height: 85vh;
  width: 90%;
  margin: 5rem 0;
  border-radius: 2rem 0 2rem 0;
  background-color: whitesmoke;
  backdrop-filter: blur(104em);
  opacity: 0.7;
  z-index: 1;
}

.data {
  border-radius: 0 0 2rem 0;
  padding: 2rem;
  padding-top: 3.5rem;
  backdrop-filter: blur(32rem);
}

.circle1, .circle2 {
  background-color: whitesmoke;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.9));
  height: 15rem;
  width: 15rem;
  z-index: -1;
  position: absolute;
  border-radius: 50%;
}
.circle1 {
  bottom: 3%;
  left: 1%;
}
.circle2 {
  top: 3%;
  right: 1%;
}

</style>
