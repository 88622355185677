<template>
  <div class="map">
    <div>
      <div class="me" @click="goHome()">
        <img src="@/assets/ico.png" width="64px" height="64px" alt="Kaustubh Murumkar">
        <h2>Kaustubh Murumkar</h2>
      </div>
      <Nav></Nav>
    </div>
    <Profiles></Profiles>
  </div>
</template>

<script>
import Nav from './Nav.vue'
import Profiles from './Profiles.vue'

export default {
  name: 'Map',
  components: {
    Nav,
    Profiles
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

.map {
  display: flex;
  flex-direction: column;
  /* width: 20%; */
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 2rem 0 0 0;
}

.me {
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 24px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .map {
    width: 100%;
  }
}
</style>
