import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/qualifications',
    name: 'Home',
    component: () => import('@/views/Qual.vue')
  },
  {
    path: '/experience',
    name: 'Experience',
    component: () => import('../views/Exp.vue')
  },
  {

    path: '/',
    name: 'AboutMe',
    component: () => import('../views/AboutMe.vue')
  },
  {
    path: '/skills',
    name: 'Skills',
    component: () => import('../views/Skl.vue')
  },
  {
    path: '/languages',
    name: 'Languages',
    component: () => import('../views/Lang.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
